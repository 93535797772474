import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Бондын өгөөжийн тооцоолуур хэрхэн ашиглах вэ?",
  "description": null,
  "author": "OTC help",
  "category": "calculator",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Бондын өгөөжийн тооцоолуур хэрхэн ашиглах вэ?`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Оролцогч үндсэн цэсний `}<strong parentName="p">{`[Calculator]`}</strong>{` хэсэг дэх тооцоолуурыг ашиглан бондын үнэ болон өгөөжийг тооцох боломжтой`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Бондын өгөөжийг тооцохдоо `}<strong parentName="p">{`[Bond Yield]`}</strong>{` цэс дээр дарж сонгосон бондын өгөгдлүүдийг дараах байдлаар оруулна: - `}<strong parentName="p">{`[Current Price]`}</strong>{` хэсэгт бондын зах зээлийн үнэ, - `}<strong parentName="p">{`[Par Value]`}</strong>{` хэсэгт бондын нэрлэсэн үнэ, - `}<strong parentName="p">{`[Annual Coupon Rate]`}</strong>{` хэсэгт жилийн хүү, - `}<strong parentName="p">{`[Discount Bond]`}</strong>{` хэсэгт тухайн бонд хөнгөлөлттэй бонд эсэхийг тэмдэглэнэ - `}<strong parentName="p">{`[Maturity in Months]`}</strong>{` хэсэгт хугацаа, сараар - `}<strong parentName="p">{`[Issue Date]`}</strong>{` хэсэгт бондын арилжаа эхэлсэн огноо - `}<strong parentName="p">{`[Price Calculation Date]`}</strong>{` хэсэгт өгөөж тооцож буй өдрийн огноо - `}<strong parentName="p">{`[Coupon Payment Frequency]`}</strong>{` хэсэгт хүү төлөх хугацаа/давтамжийг `}<strong parentName="p">{`[Monthly]`}</strong>{` сар бүр, `}<strong parentName="p">{`[Quarterly]`}</strong>{` улирал бүр, `}<strong parentName="p">{`[Twice a year]`}</strong>{` хагас жил тутамд, `}<strong parentName="p">{`[Annual]`}</strong>{` жил бүр, `}<strong parentName="p">{`[At Maturity]`}</strong>{` хугацааны эцэст гэсэн сонголтуудаас сонгон оруулна.
Дээрх өгөгдлүүдийг оруулан `}<strong parentName="p">{`[Calculate]`}</strong>{` товчийг дарснаар өгөөж тооцоолж буй өдрийн байдлаарх бондын өгөөжийг `}<strong parentName="p">{`[Current yield]`}</strong>{` таван орны нарийвчлалтайгаар тооцон харуулна.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Тооцоолуураар гарсан үр дүнг `}<strong parentName="p">{`[Export CSV]`}</strong>{` товчийг дарж .csv өргөтгөлтэйгөөр татаж авах боломжтой.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      